import React from 'react';
import { Route, NotFound } from 'cccisd-laravel-boilerplate';
import defaultProps from 'recompose/defaultProps.js';
import { Switch } from 'react-router-dom';
import { AdminRoutes, Manage as AppdefManage } from 'cccisd-laravel-appdefs';
import { AuthRedirect } from 'cccisd-laravel-nexus';
import { Quest } from 'cccisd-laravel-assignment';
import { ResourceCenter, ResourceManager } from 'cccisd-laravel-resources';
// Layouts
import MainLayout from './layouts/MainLayout';
import QuestLayout from './layouts/QuestLayout';

// Pages
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import Welcome from './pages/Welcome'; // Include it into main bundle
import RegisterLogin from './pages/RegisterLogin';
import Courses from './pages/Courses';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
// import SurveyRouting from './components/SurveyRouting';
// Laravel packagesß
import Nexus from 'bundle-loader?lazy!cccisd-laravel-nexus';

// Bind MainLayout by default
const AppRoute = defaultProps({ layout: MainLayout })(Route);

const MainLayoutFluid = defaultProps({ className: 'container-fluid' })(MainLayout);
const MainLayoutSecondaryNav = defaultProps({ showSecondaryNav: true })(MainLayout);
const MainLayoutFluidSecondaryNav = defaultProps({
    className: 'container-fluid',
    showSecondaryNav: true,
})(MainLayout);

let Fortress = window.cccisd.fortress;
let AppDefinitions = window.cccisd.appDefs;

const componentMatch = {
    AppdefManage,
    ResourceCenter,
    Welcome,
    Dashboard,
};

const layoutMatch = {
    MainLayout,
    MainLayoutFluid,
    MainLayoutSecondaryNav,
    MainLayoutFluidSecondaryNav,
};

export default () => (
    <Switch>
        {AppDefinitions.routes.map(route => {
            const Component = componentMatch[route.componentHandle];
            if (!Component) {
                return null;
            }

            return (
                <AppRoute
                    key={route.handle}
                    path={route.url}
                    component={Component}
                    componentProps={route.componentProps}
                    exact
                    layout={
                        'layout' in route && layoutMatch[route.layout] ? layoutMatch[route.layout] : MainLayoutFluid
                    }
                />
            );
        })}
        <AppRoute path="/" component={Welcome} layout={MainLayoutFluid} exact />
        <AppRoute path="/welcome" component={Welcome} layout={MainLayout} />
        <AppRoute path="/register" component={RegisterLogin} />
        <AppRoute path="/courses" component={Courses} layout={MainLayoutFluid} />
        <AppRoute path="/login" component={Login} layout={MainLayout} />
        {/* <AppRoute path="/d/:deploymentHash/:groupId/:deploymentHandle" component={SurveyRouting} layout={QuestLMainLayoutayout} /> */}
        <AppRoute
            path={['/quest/preview', '/d', '/survey']}
            component={Quest}
            layout={MainLayout}
            componentProps={{ registrationUrlsByRole: { participant: '/register' } }}
        />

        {/* Laravel packages routes */}
        {AdminRoutes(MainLayoutFluid)}

        <AppRoute
            path="/account"
            layout={null}
            component={Nexus}
            componentProps={{ defaultLayout: MainLayout, registrationSurveyLayout: QuestLayout }}
        />

        {Fortress.auth() && (Fortress.user.acting.role.handle === 'uberadmin' || Fortress.isSuperUser()) && (
            <AppRoute path="/resources/admin" component={ResourceManager} />
        )}

        <AppRoute path={Fortress.settings.config.after_login_url} component={AuthRedirect} exact />

        {/* Not found page */}
        <AppRoute component={NotFound} />
    </Switch>
);
